<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.73408 6.5095H0V10.7833H2.73408L6.84 13.8769V3.41528L2.73408 6.5095Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.35713 12.6637L8.68652 12.0201C10.3735 10.4004 10.3735 7.76544 8.68652 6.14572L9.35713 5.50269C11.4146 7.47682 11.4146 10.689 9.35713 12.6637Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9362 15.1384L11.2656 14.4947C12.7717 13.0492 13.601 11.1267 13.601 9.0826C13.601 7.03854 12.7717 5.11656 11.2656 3.67107L11.9362 3.02686C13.6214 4.6448 14.5495 6.79555 14.5495 9.0826C14.5495 11.3703 13.6214 13.521 11.9362 15.1384Z"
      fill="white"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="13"
      y="0"
      width="6"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8438 0.55249H18.1959V17.6132H13.8438V0.55249Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5144 17.6133L13.8438 16.9696C16.0383 14.8627 17.2474 12.0619 17.2474 9.08257C17.2474 6.10389 16.0383 3.303 13.8438 1.19611L14.5144 0.55249C16.8886 2.83125 18.1959 5.86031 18.1959 9.08257C18.1959 12.3048 16.8886 15.3345 14.5144 17.6133Z"
        fill="white"
      />
    </g>
  </svg>
</template>
